import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";

export const LysaCanceled: React.VFC = () => {
  return (
    <div className="snackbar-wrapper">
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <FormattedMessage id="onfido.status.poa.lysa_canceled" />
      </Snackbar>
    </div>
  );
};
