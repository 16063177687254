import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";
import { StatusResponsePoa } from "../../../data/onfido";
import { NonClearedBreakdownList } from "./NonClearedBreakdownList";

interface Props {
  poaStatus: StatusResponsePoa;
}

/**
 * Lysa has rejected the POA check with onfido reasons. Or the user tried to
 * verify address with ID card, and Onfido didn't approve it
 */
export const OnfidoConsider: React.VFC<Props> = ({ poaStatus }) => {
  if (poaStatus.nonClearedBreakdowns.length) {
    return <NonClearedBreakdownList poaStatus={poaStatus} />;
  }

  if (poaStatus.checkType === "POA_ID_CARD") {
    return (
      <div className="snackbar-wrapper">
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="onfido.status.poa.onfido_consider.poa_id_card" />
        </Snackbar>
      </div>
    );
  }

  return (
    <div className="snackbar-wrapper">
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <FormattedMessage id="onfido.status.poa.onfido_consider.generic" />
      </Snackbar>
    </div>
  );
};
