import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  StatusResponsePoa,
  PoaNonClearedBreakdowns,
  // PoaSubBreakdowns,
} from "../../../data/onfido";

const breakdowns: Record<
  PoaNonClearedBreakdowns["name"],
  React.ReactElement | null
> = {
  document_classification: (
    <FormattedMessage id="onfido.status.poa.non_cleared.document_classification" />
  ),
  data_comparison: (
    <FormattedMessage id="onfido.status.poa.non_cleared.data_comparison" />
  ),
  image_integrity: (
    <FormattedMessage id="onfido.status.poa.non_cleared.image_integrity" />
  ),
};

// const subBreakdowns: Record<
//   PoaSubBreakdowns["name"],
//   React.ReactElement | null
// > = {
//   image_quality: (
//     <FormattedMessage id="onfido.status.poa.non_cleared.image_quality" />
//   ),
//   supported_document: (
//     <FormattedMessage id="onfido.status.poa.non_cleared.supported_document" />
//   ),
//   valid_document_date: (
//     <FormattedMessage id="onfido.status.poa.non_cleared.valid_document_date" />
//   ),
//   first_name: (
//     <FormattedMessage id="onfido.status.poa.non_cleared.first_name" />
//   ),
//   last_name: <FormattedMessage id="onfido.status.poa.non_cleared.last_name" />,
//   address: <FormattedMessage id="onfido.status.poa.non_cleared.address" />,
// };

interface Props {
  poaStatus: StatusResponsePoa;
}

export const NonClearedBreakdownList: React.VFC<Props> = ({ poaStatus }) => {
  const intl = useIntl();

  const getName = (type: any, name: any) => {
    return type.hasOwnProperty(name)
      ? type[name]
      : intl.formatMessage({ id: "onfido.status.poa.non_cleared.consider" }) +
          " " +
          name.replaceAll("_", " ");
  };

  if (poaStatus.nonClearedBreakdowns.length) {
    return (
      <ul className="snackbar-wrapper">
        {poaStatus.nonClearedBreakdowns.map((breakdown) => {
          return (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon key={breakdown.name}>
              <li>{getName(breakdowns, breakdown.name)}</li>
              {/* <ul className="list-breakdowns">
                {breakdown.nonClearedSubBreakdowns.map((subBreakdown) => {
                  return <li>• {getName(subBreakdowns, subBreakdown.name)}</li>;
                })}
              </ul> */}
            </Snackbar>
          );
        })}
      </ul>
    );
  }

  return null;
};
