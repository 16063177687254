import React, { useCallback } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import {
  Card,
  Spinner,
  useFlash,
  SNACKBAR_TYPES,
  Snackbar,
  Button,
} from "@lysaab/ui-2";
import "./OnfidoProcessPage.scss";
import { ConfirmResponse, ConfirmStatus, ResetId } from "../../data/onfido";
import { Onfido } from "./Onfido";
import { SignupId } from "../../data/signup";
import { LysaCountry } from "@lysaab/shared";
import { Steps, DocumentType } from "./StepsConfigurator";
import { JwtResponse, useOnfidoToken } from "../../hooks/useOnfidoToken";
import * as OnfidoSdk from "onfido-sdk-ui";

const messages = defineMessages({
  onfidoInternalError: { id: "onfido.process.onfido_internal_error" },
  onfidoTokenRetry: { id: "onfido.process.token_retry" },
  onfidoTokenError: { id: "onfido.process.token_error" },
  onfidoInvalidLink: { id: "onfido.process.invalid_link" },
});

interface Props {
  next: () => void;
  restart: () => void;
  onUserError: (e: any) => void;
  onFatalError: () => void;
  confirm: (data?: any) => Promise<ConfirmResponse>;
  getToken: () => Promise<JwtResponse>;
  country: LysaCountry;
  locale: string;
  phrases?: Record<string, unknown>;
  steps: Steps;
  allowedDocuments?: DocumentType[];
  signupId?: SignupId;
  resetId?: ResetId;
  track?: (event: any) => void;
}

let refetchCount = 0;

/**
 * It's not safe to navigate back to this page. It's recommended to keep
 * external state to decide whether to render this page, or the
 * OnfidoCompletedPage
 */
export const OnfidoProcessPage: React.FC<Props> = ({
  next,
  restart,
  onUserError,
  onFatalError,
  confirm,
  getToken,
  country,
  locale,
  phrases = {},
  steps,
  /** If not included it will default to passport and national_identity_card */
  allowedDocuments,
  signupId,
  resetId,
  track,
}) => {
  const pushFlash = useFlash();
  const { formatMessage } = useIntl();

  const { token, loading, error, resetToken, refetchToken } =
    useOnfidoToken(getToken);

  const showSpinner = !token || loading;

  // Moved outside the useCallback bellow to stop it from re-running on every
  // render.
  // const onfidoTokenErrorMessage = formatMessage(messages.onfidoTokenError);
  // const onfidoTokenRetryMessage = formatMessage(messages.onfidoTokenRetry);
  // const onfidoInvalidLinkMessage = formatMessage(messages.onfidoInvalidLink);

  const onError = useCallback(
    (data: OnfidoSdk.SdkError) => {
      if (data.type !== "expired_token") {
        pushFlash({
          text: formatMessage(messages.onfidoInternalError),
          type: SNACKBAR_TYPES.ERROR,
        });

        console.error(data);
      }

      setTimeout(() => {
        if (refetchCount < 3) {
          refetchToken();
          refetchCount++;
        } else {
          console.error(data);
          pushFlash({
            text: formatMessage(messages.onfidoInternalError),
            type: SNACKBAR_TYPES.ERROR,
          });
        }
      }, 2000);
    },
    [formatMessage, refetchToken, pushFlash]
  );

  const internalOnConfirm = useCallback(
    (data?: any) => {
      confirm(data)
        .then((status) => {
          if (status.checkStatus === ConfirmStatus.OK) {
            resetToken();
            // Automatically go to next step as soon as onfido has called the
            // complete callback and we've confirmed to our backend
            // This also serves as a workaround for
            // https://github.com/onfido/onfido-sdk-ui/issues/1503
            next();
          } else {
            // TODO: Use actual statuses from incoming parameter `status`
            onUserError({ checkStatus: "ONFIDO_USER_ERROR" });
          }
        })
        .catch(() => {
          onFatalError();
        });
    },
    [confirm, resetToken, next, onUserError, onFatalError]
  );

  return (
    <div className="onfido-process-page">
      <Card>
        <div className="overlay-wrapper-position">
          {error && <Snackbar type={SNACKBAR_TYPES.ERROR}>{error}</Snackbar>}
          {showSpinner && (
            <div className="loading-wrapper">
              <Spinner />
              <div className="loading-info">
                <p className="loading-text">
                  <FormattedMessage id="onfido.process.loading" />
                  <span className="ellipsis">&hellip;</span>
                </p>
              </div>
            </div>
          )}
        </div>

        <Onfido
          className={showSpinner ? "hidden-mount" : ""}
          confirm={internalOnConfirm}
          country={country}
          locale={locale}
          steps={steps}
          allowedDocuments={allowedDocuments}
          phrases={phrases}
          token={token}
          onError={onError}
          signupId={signupId}
          resetId={resetId}
          track={track}
        />
      </Card>

      {error && !showSpinner && (
        <Button
          onClick={restart}
          block
          label={<FormattedMessage id="onfido.process.button.error" />}
        />
      )}
    </div>
  );
};
