import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Card, Spinner } from "@lysaab/ui-2";

interface Props {
  next?: () => void;
}

export const OnfidoDonePage: React.FC<Props> = ({ next }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setCounter((old) => old + 1);
    }, 1000);

    return () => clearInterval(id);
  }, [setCounter]);

  useEffect(() => {
    if (counter > 35) {
      next?.();
    }
  }, [counter, next]);

  return (
    <div className="onfido-done-page">
      <h2>Processing</h2>
      <Card>
        <FormattedMessage id="onfido.done" />
        <Spinner />
        {counter > 10 && (
          <p>
            <FormattedMessage id="onfido.done.processing" />
          </p>
        )}
        {counter > 30 && (
          <p>
            <FormattedMessage id="onfido.done.long_wait" />
          </p>
        )}
        {counter > 40 && <p>Nu är det klart.</p>}
        {counter > 100 && (
          <p>Det här är sist texten. Du behöver inte vänta mer.</p>
        )}
        {counter > 200 && (
          <p>
            Hur orkar du? Har du inget bättre att gör än att sitta här och vänta
            på texter?{" "}
            <span role="img" aria-label="grin emoji">
              😁
            </span>
          </p>
        )}
      </Card>
    </div>
  );
};
