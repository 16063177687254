import { useState, useEffect, useContext, useCallback } from "react";
import { IntlProvider } from "react-intl";
import { Story, FlashOverlay } from "@lysaab/ui-2";
import { OnfidoContextProvider } from "./context/OnfidoContext";
import { OnfidoIntroPage } from "./pages/Intro/OnfidoIntroPage";
import { OnfidoDonePage } from "./pages/done/OnfidoDonePage";
import svMessages from "./locales/sv.json";
import enMessages from "./locales/en.json";
import deMessages from "./locales/de.json";
import {
  Language,
  LocalizationContext,
  LocalizationContextProvider,
} from "./lang/LocalizationContext";
import { GetTokenData } from "./data/onfidoDemo";
import {
  BrowserRouter,
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { OnfidoStatusPage } from "./pages/status/OnfidoStatusPage";
import { SignupId } from "./data/signup";
import { LysaCountry } from "@lysaab/shared";
import "./App.scss";
import { DemoOnfidoIdPage } from "./demo/DemoOnfidoIdPage";
import { DemoOnfidoPoaPage } from "./demo/DemoOnfidoPoaPage";
import { DemoDataForm } from "./demo/DemoDataForm";
import { OnfidoPoaIntroPage } from "./pages/poaIntro/OnfidoPoaIntroPage";

declare global {
  interface Window {
    setLanguage?: (lang: Language) => void;
  }
}

const languagePacks = {
  [Language.ENGLISH]: enMessages,
  [Language.SWEDISH]: svMessages,
  [Language.GERMAN]: deMessages,
};

const ROUTES = {
  ONFIDO_INTRO: "/",
  ONFIDO_STATUS: "/onfido-status/:signupId",
  ONFIDO_ID: "/onfido-id/:signupId",
  ONFIDO_POA_INTRO: "/onfido-poa-intro/:signupId",
  ONFIDO_POA: "/onfido-poa/:signupId",
  ONFIDO_DONE: "/onfido-done",
};

function InnerApp() {
  const location = useLocation();
  const history = useHistory();
  const localizationContext = useContext(LocalizationContext);
  const [signupId, setSignupId] = useState<SignupId>(
    crypto.randomUUID() as SignupId
  );
  const [getTokenData, setGetTokenData] = useState<GetTokenData>({
    firstName: "John",
    lastName: "Carmack",
    dateOfBirth: "1979-08-20",
    tin: "200879-1337",
    countryCode: "DE",
    residentCountry: "DE",
    street: "1500 N Greenville Ave Ste 700",
    city: "Richardson",
    postalCode: "75081-2271",
    email: "doom@idsoftware.com",
    signupId,
  });

  useEffect(() => {
    if (!localizationContext.state.country) {
      localizationContext.setState({
        language: Language.ENGLISH,
        country: LysaCountry.DENMARK,
      });
    }
  }, [localizationContext]);

  useEffect(() => {
    window.setLanguage = (lang: Language) => {
      localizationContext.setState({ language: lang });
    };

    return () => {
      delete window.setLanguage;
    };
  }, [localizationContext]);

  const currentIndex = Object.values(ROUTES).findIndex(
    (path) =>
      matchPath(location.pathname, {
        path,
        exact: path === ROUTES.ONFIDO_INTRO,
      }) !== null
  );

  const nextCb = useCallback(
    () => history.push(ROUTES.ONFIDO_POA_INTRO.replace(":signupId", signupId)),
    [history, signupId]
  );

  return (
    <IntlProvider
      locale="en-DK"
      defaultLocale="en"
      messages={languagePacks[localizationContext.state.language] as any}
    >
      <Story
        header="Onfido Lib"
        showBack={currentIndex > 0}
        showClose={false}
        onBack={() => history.goBack()}
        transitionKey={currentIndex.toString()}
        onExit={() => {}}
        progress={((currentIndex + 1) / Object.keys(ROUTES).length) * 100}
        ariaLabelProgress={() =>
          `Step ${currentIndex + 1} of ${Object.keys(ROUTES).length}`
        }
      >
        <Switch {...{ order: currentIndex }} location={location}>
          <Route path={ROUTES.ONFIDO_INTRO} exact>
            <OnfidoIntroPage
              next={() => {
                history.push(ROUTES.ONFIDO_ID.replace(":signupId", signupId));
              }}
              signupId={signupId}
              language={Language.ENGLISH}
              country={LysaCountry.DENMARK}
            />
          </Route>
          <Route path={ROUTES.ONFIDO_STATUS}>
            <OnfidoStatusPage
              idProcess={() => {
                history.push(ROUTES.ONFIDO_ID.replace(":signupId", signupId));
              }}
              poaProcess={() => {
                history.push(ROUTES.ONFIDO_POA.replace(":signupId", signupId));
              }}
              restart={() => {
                window.location.reload();
              }}
            />
          </Route>
          <Route path={ROUTES.ONFIDO_ID}>
            <DemoOnfidoIdPage getTokenData={getTokenData} next={nextCb} />
          </Route>
          <Route path={ROUTES.ONFIDO_POA_INTRO} exact>
            <OnfidoPoaIntroPage
              next={() => {
                history.push(ROUTES.ONFIDO_POA.replace(":signupId", signupId));
              }}
              signupId={signupId}
              language={Language.ENGLISH}
            />
          </Route>
          <Route path={ROUTES.ONFIDO_POA}>
            <DemoOnfidoPoaPage
              getTokenData={getTokenData}
              next={() => history.push(ROUTES.ONFIDO_DONE)}
            />
          </Route>
          <Route path={ROUTES.ONFIDO_DONE}>
            <OnfidoDonePage />
          </Route>
        </Switch>
      </Story>
      <DemoDataForm
        getTokenData={getTokenData}
        setGetTokenData={setGetTokenData}
        signupId={signupId}
        setSignupId={setSignupId}
        pageIndex={currentIndex}
      />
    </IntlProvider>
  );
}

function App() {
  return (
    <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
      <LocalizationContextProvider>
        <BrowserRouter>
          <FlashOverlay>
            <OnfidoContextProvider>
              <InnerApp />
            </OnfidoContextProvider>
          </FlashOverlay>
        </BrowserRouter>
      </LocalizationContextProvider>
    </div>
  );
}

export default App;
