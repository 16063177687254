import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  Card,
  LysaLink,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { getSignupInfo, SignupId, SignupInfoResponse } from "../../data/signup";
import { matchPath } from "react-router";
import "./OnfidoStatusPage.scss";
import { useParams } from "react-router-dom";
import { LysaCountry } from "@lysaab/shared";

function getLinkPrefix(pathname: string = window.location.pathname) {
  const match = matchPath<{ country: string }>(pathname, {
    path: "/:country",
  });
  const country = match?.params.country ?? "";

  if (
    Object.values(LysaCountry).includes(country.toUpperCase() as LysaCountry)
  ) {
    return country;
  }

  return "";
}

export const SignupInfo = () => {
  const [signupData, setSignupData] = useState<SignupInfoResponse>();
  const { signupId } = useParams<{ signupId: SignupId }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSignupInfo(signupId)
      .then((response) => {
        setSignupData(response);
      })
      .catch((error) => {
        // One reason for ending up here is if the signupId is malformed (not
        // a valid uuid4)
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [signupId]);

  const restartLink = new URL(
    getLinkPrefix(),
    window.location.origin
  ).toString();

  return (
    <div className="signup-info">
      <Card>
        <h3>
          <FormattedMessage id="onfido.signup_info.header" />
        </h3>
        {loading && <Spinner />}
        {!loading && !signupData && (
          <div className="info">
            <FormattedMessage
              id="onfido.signup_info.no_signup_data"
              values={{
                link: (chunks: React.ReactNode[]) => (
                  <LysaLink href={restartLink}>{chunks}</LysaLink>
                ),
              }}
            />
          </div>
        )}
        {!loading && signupData && (
          <>
            <div className="info">
              <p>
                {signupData?.firstName} {signupData?.lastName}
              </p>
              {/* @ts-expect-error Fixme */}
              <p>{signupData?.dateOfBirth}</p>
              <p>{signupData?.street}</p>
              <p>
                {signupData?.postalCode} {signupData?.city}
              </p>
              <p>{signupData?.residentCountry}</p>
            </div>
            <Snackbar type={SNACKBAR_TYPES.INFO}>
              <FormattedMessage
                id="onfido.signup_info.info"
                values={{
                  link: (chunks: React.ReactNode[]) => (
                    <LysaLink href={restartLink}>{chunks}</LysaLink>
                  ),
                }}
              />
            </Snackbar>
          </>
        )}
      </Card>
    </div>
  );
};
