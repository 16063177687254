import { API } from "@lysaab/ui-2";
import { ConfirmResponse } from "./onfido";
import { SignupId } from "./signup";

interface JwtResponse {
  token: string;
}

export interface GetTokenData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  tin: string;
  countryCode: string;
  residentCountry: string;
  street: string;
  city: string;
  postalCode: string;
  email: string;
  signupId: SignupId;
}

export function getToken(data: GetTokenData) {
  const url = "/onfido/test-token";

  return API.post<JwtResponse>(url, data);
}

// export function initiate(signupId: SignupId) {
//   const url = "/onfido/onboarding/initiate";

//   return API.post<JwtResponse>(url, { signupId });
// }

export function confirmId(signupId: SignupId) {
  const url = "/onfido/onboarding/id/confirm?signupId=" + signupId;

  return API.post<ConfirmResponse>(url);
}

export function confirmPoa(signupId: SignupId, documentId: string) {
  const url = "/onfido/onboarding/poa/confirm";

  return API.post<ConfirmResponse>(url, { signupId, documentId });
}