import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useOnfidoStatus } from "../../hooks/useOnfidoStatus";
import { SignupId } from "../../data/signup";
import { IdStatus } from "./idStatus/IdStatus";
import { PoaStatus } from "./poaStatus/PoaStatus";

interface Props {
  signupId: SignupId;
}

export const StatusCardContent: React.VFC<Props> = ({ signupId }) => {
  const { idStatus, poaStatus, loading, hasStatus, error } =
    useOnfidoStatus(signupId);

  if (loading) {
    return (
      <div className="loading-wrapper">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR}>
        <FormattedMessage id="onfido.status.retry.message" />
      </Snackbar>
    );
  }

  if (!hasStatus) {
    return (
      <p>
        <FormattedMessage id="onfido.status.no_statuses" />
      </p>
    );
  }

  if (hasStatus) {
    return (
      <>
        <p>
          <FormattedMessage id="onfido.status.general_info" />
        </p>

        <h3>
          <FormattedMessage id="onfido.status.id.header" />
        </h3>
        <IdStatus idStatus={idStatus} />

        <h3>
          <FormattedMessage id="onfido.status.poa.header" />
        </h3>
        <PoaStatus poaStatus={poaStatus} />
      </>
    );
  }

  return null;
};
