import React from "react";
import { FormattedMessage } from "react-intl";
import { OnfidoPending } from "./OnfidoPending";
import { OnfidoPendingManualReview } from "./OnfidoPendingManualReview";
import { Clear } from "./Clear";
import { LysaManualReviewRejected } from "./LysaManualReviewRejected";
import { StatusResponsePoa } from "../../../data/onfido";
import { OnfidoConsider } from "./OnfidoConsider";
import { LysaManualReview } from "./LysaManualReview";
import { LysaCanceled } from "./LysaCanceled";
import { OnfidoFailedUserError } from "./OnfidoFailedUserError";
import { OnfidoFailedStarted } from "./OnfidoFailedStarted";

interface Props {
  poaStatus?: StatusResponsePoa;
}

export const PoaStatus: React.VFC<Props> = ({ poaStatus }) => {
  if (!poaStatus) {
    return <FormattedMessage id="onfido.status.poa.not_started" />;
  }

  const status: Record<StatusResponsePoa["status"], React.ReactElement | null> =
    {
      ONFIDO_PENDING: <OnfidoPending />,
      ONFIDO_PENDING_MANUAL_REVIEW: <OnfidoPendingManualReview />,
      ONFIDO_CONSIDER: <OnfidoConsider poaStatus={poaStatus} />,
      LYSA_CANCELED: <LysaCanceled />,
      ONFIDO_FAILED_STARTED: <OnfidoFailedStarted />,
      ONFIDO_FAILED_USER_ERROR: <OnfidoFailedUserError />,
      ONFIDO_CLEAR: <Clear />,
      LYSA_MANUAL_REVIEW: <LysaManualReview />,
      LYSA_MANUAL_REVIEW_CLEAR: <Clear />,
      LYSA_MANUAL_REVIEW_REJECTED: (
        <LysaManualReviewRejected poaStatus={poaStatus} />
      ),
    };

  return status[poaStatus.status];
};
