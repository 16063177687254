import React, { useEffect, useRef } from "react";
import * as OnfidoSdk from "onfido-sdk-ui";
import { getSteps, Steps, DocumentType } from "./StepsConfigurator";
import { logSignup, logReset, ResetId } from "../../data/onfido";
import { SignupId } from "../../data/signup";
import { LysaCountry } from "@lysaab/shared";

interface Props {
  token?: string;
  className?: string;
  country: LysaCountry;
  locale: string;
  phrases: Record<string, unknown>;
  steps: Steps;
  allowedDocuments?: DocumentType[];
  confirm: (data?: OnfidoSdk.SdkResponse) => void;
  onError: (data: OnfidoSdk.SdkError) => void;
  signupId?: SignupId;
  resetId?: ResetId;
  track?: (event: any) => void;
}

export const Onfido: React.FC<Props> = ({
  token,
  className,
  country,
  locale,
  steps,
  allowedDocuments,
  phrases,
  confirm,
  onError,
  signupId,
  resetId,
  track,
}) => {
  const onfidoMountId = useRef(
    "onfido_" + Math.random().toString(36).slice(2, 11)
  );

  /**
   * This will allow us to do a deep compare and prevent unnecessary
   * changes from our dependency array
   */
  const comparablePhrases = JSON.stringify(phrases);
  const comparableSteps = JSON.stringify(steps);
  const comparableAllowedDocuments = JSON.stringify(allowedDocuments);

  useEffect(() => {
    const onUserAnalyticsEvent = (event: any) => {
      if (signupId && track) {
        logSignup(signupId, event.detail);
      }

      if (track) {
        track(event.detail);
      }

      if (resetId) {
        logReset(resetId, event.detail);
      }
    };
    window.addEventListener("userAnalyticsEvent", onUserAnalyticsEvent);

    if (!token) {
      return;
    }

    const onfido = OnfidoSdk.init({
      useModal: false,
      steps: getSteps(
        comparableSteps ? JSON.parse(comparableSteps) : undefined,
        comparableAllowedDocuments
          ? JSON.parse(comparableAllowedDocuments)
          : undefined
      ),
      token,
      containerId: onfidoMountId.current,
      language: {
        locale: locale as OnfidoSdk.SupportedLanguages,
        phrases: JSON.parse(comparablePhrases),
      },
      smsNumberCountryCode: country.toString(),
      onComplete: confirm,
      onError: onError,
    });

    return () => {
      window.removeEventListener("userAnalyticsEvent", onUserAnalyticsEvent);
      onfido.tearDown();
    };
  }, [
    confirm,
    country,
    locale,
    onError,
    token,
    comparablePhrases,
    comparableSteps,
    comparableAllowedDocuments,
    signupId,
    resetId,
    track,
  ]);

  return (
    <div
      id={onfidoMountId.current}
      className={"onfido-mount " + className}
    ></div>
  );
};
