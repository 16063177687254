import React from 'react'
import { Spinner } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";

export const OnfidoPendingManualReview: React.VFC = () => {
  return (
    <div className="pending-container">
      <Spinner />
      <div>
        <FormattedMessage id="onfido.status.poa.waiting_for_onfido_manual_review" />
      </div>
    </div>
  );
};
