import React from 'react'
import { FormattedMessage } from "react-intl";
import { Spinner } from "@lysaab/ui-2";

export const LysaManualReview: React.VFC = () => {
  return (
    <div className="pending-container">
      <Spinner />
      <div>
        <FormattedMessage id="onfido.status.id.waiting_for_lysa_manual_review" />
      </div>
    </div>
  );
};
