import React from "react";
import { FormattedMessage } from "react-intl";
import { StatusResponseId } from "../../../Exports";
import { OnfidoDocumentFailedType } from "./OnfidoDocumentFailedType";
import { OnfidoConsider } from "./OnfidoConsider";
import { OnfidoPending } from "./OnfidoPending";
import { OnfidoPendingManualReview } from "./OnfidoPendingManualReview";
import { LysaManualReview } from "./LysaManualReview";
import { Clear } from "./Clear";
import { LysaManualReviewRejected } from "./LysaManualReviewRejected";
import { OnfidoCanceled } from "./OnfidoCanceled";
import { OnfidoFailedUserError } from "./OnfidoFailedUserError";
import { OnfidoFailedStarted } from "./OnfidoFailedStarted";

interface Props {
  idStatus?: StatusResponseId;
}

export const IdStatus: React.VFC<Props> = ({ idStatus }) => {
  if (!idStatus) {
    return <FormattedMessage id="onfido.status.id.not_started" />;
  }

  const status: Record<StatusResponseId["status"], React.ReactElement | null> =
    {
      ONFIDO_PENDING: <OnfidoPending />,
      ONFIDO_PENDING_MANUAL_REVIEW: <OnfidoPendingManualReview />,
      LYSA_MANUAL_REVIEW: <LysaManualReview />,
      ONFIDO_CLEAR: <Clear />,
      LYSA_MANUAL_REVIEW_CLEAR: <Clear />,
      ONFIDO_CONSIDER: <OnfidoConsider idStatus={idStatus} />,
      LYSA_CANCELED: <OnfidoCanceled />,
      ONFIDO_FAILED_STARTED: <OnfidoFailedStarted />,
      ONFIDO_FAILED_USER_ERROR: <OnfidoFailedUserError />,
      LYSA_MANUAL_REVIEW_REJECTED: (
        <LysaManualReviewRejected idStatus={idStatus} />
      ),
      ONFIDO_DOCUMENT_FAILED_TYPE: (
        <OnfidoDocumentFailedType idStatus={idStatus} />
      ),
    };

  return status[idStatus.status];
};
