import * as Onfido from "onfido-sdk-ui";

export const STEP_DOCUMENT = "document";
export const STEP_FACE = "face";
export const STEP_POA = "poa";
export const STEP_COMPLETE = "complete";

export type DocumentType =
  | "passport"
  | "national_identity_card"
  | "driving_licence";

// export type StepType =
//   | typeof STEP_DOCUMENT
//   | typeof STEP_FACE
//   | typeof STEP_POA
//   | typeof STEP_COMPLETE;

type ValidSteps = {
  face: [typeof STEP_FACE, typeof STEP_COMPLETE]; // Password reset
  poa: [typeof STEP_POA, typeof STEP_COMPLETE];
  id: [typeof STEP_DOCUMENT, typeof STEP_FACE]; // Signup with successful database ID check
  idPoa: [typeof STEP_DOCUMENT, typeof STEP_FACE, typeof STEP_POA];
};

export type Steps =
  | ValidSteps["face"]
  | ValidSteps["id"]
  | ValidSteps["poa"]
  | ValidSteps["idPoa"];

function isStepsWithDocument(
  steps: Steps
): steps is ValidSteps["id"] | ValidSteps["idPoa"] {
  return (steps as ValidSteps["id"] | ValidSteps["idPoa"]).includes(
    STEP_DOCUMENT
  );
}

function isStepsWithFace(
  steps: Steps
): steps is ValidSteps["face"] | ValidSteps["id"] | ValidSteps["idPoa"] {
  return (
    steps as ValidSteps["face"] | ValidSteps["id"] | ValidSteps["idPoa"]
  ).includes(STEP_FACE);
}

function isStepsWithPoa(
  steps: Steps
): steps is ValidSteps["poa"] | ValidSteps["idPoa"] {
  return (steps as ValidSteps["poa"] | ValidSteps["idPoa"]).includes(STEP_POA);
}

function isStepsWithComplete(
  steps: Steps
): steps is ValidSteps["face"] | ValidSteps["poa"] {
  return (steps as ValidSteps["face"] | ValidSteps["poa"]).includes(
    STEP_COMPLETE
  );
}

export function getSteps(steps: Steps, allowedDocuments?: DocumentType[]) {
  const stepConfigurations: Array<
    Onfido.PublicStepConfig | Onfido.PublicStepTypes
  > = [];

  if (isStepsWithDocument(steps)) {
    stepConfigurations.push({
      type: STEP_DOCUMENT,
      options: {
        forceCrossDevice: true,
        useLiveDocumentCapture: true, // Only tested on: Android Chrome 78.0.3904.108, iOS Safari 13 according to Onfido docs
        uploadFallback: true, // For other browsers than listed above
        documentTypes: getAllowedDocumentTypes(allowedDocuments),
      },
    });
  }

  if (isStepsWithFace(steps)) {
    stepConfigurations.push(STEP_FACE);
  }

  if (isStepsWithPoa(steps)) {
    stepConfigurations.push({
      type: STEP_POA,
      options: {
        documentTypes: {
          // Council Tax and Benefits Letter are only supported if issued by the UK.
          // Government Letter is not supported anymore in newer Onfido api versions
          utility_bill: true,
          bank_building_society_statement: true,
          council_tax: false,
          benefit_letters: false,
          government_letter: false,
        },
      },
    });
  }

  if (isStepsWithComplete(steps)) {
    stepConfigurations.push(STEP_COMPLETE);
  }

  return stepConfigurations;
}

const DEFAULT_ALLOWED_DOCUMENT_TYPES: Partial<
  Record<Onfido.DocumentTypes, Onfido.DocumentTypeConfig>
> = {
  passport: true,
  national_identity_card: true,
  // driving_licence: false,
  // residence_permit: false,
};

function getAllowedDocumentTypes(
  allowedDocuments?: DocumentType[]
): Partial<Record<Onfido.DocumentTypes, Onfido.DocumentTypeConfig>> {
  if (!allowedDocuments) {
    return DEFAULT_ALLOWED_DOCUMENT_TYPES;
  }

  return allowedDocuments.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: true,
    };
  }, {});
}
