import { GetTokenData } from "../data/onfidoDemo";
import { SignupId } from "../data/signup";

interface Props {
  getTokenData: GetTokenData;
  setGetTokenData: (data: GetTokenData) => void;
  signupId: SignupId;
  setSignupId: (signupId: SignupId) => void;
  pageIndex: number;
}

export const DemoDataForm: React.VFC<Props> = ({
  getTokenData,
  setGetTokenData,
  signupId,
  setSignupId,
  pageIndex,
}) => {
  return (
    <form className="demo-data">
      <label>
        First Name:
        <input
          value={getTokenData.firstName}
          onChange={(e) => {
            setGetTokenData({ ...getTokenData, firstName: e.target.value });
          }}
          disabled={pageIndex !== 0}
        />
      </label>
      <label>
        Last Name:
        <input
          value={getTokenData.lastName}
          onChange={(e) => {
            setGetTokenData({ ...getTokenData, lastName: e.target.value });
          }}
          disabled={pageIndex !== 0}
        />
      </label>
      <label>
        Date of Birth (yyyy-mm-dd):
        <input
          value={getTokenData.dateOfBirth}
          onChange={(e) => {
            setGetTokenData({ ...getTokenData, dateOfBirth: e.target.value });
          }}
          disabled={pageIndex !== 0}
        />
      </label>
      <label>
        Tin:
        <input
          value={getTokenData.tin}
          onChange={(e) => {
            setGetTokenData({ ...getTokenData, tin: e.target.value });
          }}
          disabled={pageIndex !== 0}
        />
      </label>
      <label>
        City:
        <input
          value={getTokenData.city}
          onChange={(e) => {
            setGetTokenData({ ...getTokenData, city: e.target.value });
          }}
          disabled={pageIndex !== 0}
        />
      </label>
      <label>
        Postal Code:
        <input
          value={getTokenData.postalCode}
          onChange={(e) => {
            setGetTokenData({ ...getTokenData, postalCode: e.target.value });
          }}
          disabled={pageIndex !== 0}
        />
      </label>
      <label>
        Street:
        <input
          value={getTokenData.street}
          onChange={(e) => {
            setGetTokenData({ ...getTokenData, street: e.target.value });
          }}
          disabled={pageIndex !== 0}
        />
      </label>
      <label>
        Email:
        <input
          value={getTokenData.email}
          onChange={(e) => {
            setGetTokenData({ ...getTokenData, email: e.target.value });
          }}
          disabled={pageIndex !== 0}
        />
      </label>
      <label>
        SignupId:
        <input
          value={signupId}
          onChange={(e) => {
            setGetTokenData({
              ...getTokenData,
              signupId: e.target.value as SignupId,
            });
            setSignupId(e.target.value as SignupId);
          }}
          disabled={pageIndex !== 0}
        />
      </label>
    </form>
  );
};
