import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";
import { StatusResponsePoa } from "../../../data/onfido";

interface Props {
  poaStatus: StatusResponsePoa;
}

export const LysaManualReviewRejected: React.VFC<Props> = ({ poaStatus }) => {
  return (
    <span>
      {poaStatus.manualRejectReasons.length && (
        <ul className="snackbar-wrapper">
          {poaStatus.manualRejectReasons.map((reason) => {
            if (reason === "POA_CHECK_UNSUPPORTED_DOCUMENT") {
              return (
                <li key={reason}>
                  <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                    <FormattedMessage id="onfido.status.poa.check.unsupported_document" />
                  </Snackbar>
                </li>
              );
            }

            if (reason === "POA_CHECK_INSUFFICIENT_IMAGE_QUALITY") {
              return (
                <li key={reason}>
                  <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                    <FormattedMessage id="onfido.status.poa.check.insufficient_image_quality" />
                  </Snackbar>
                </li>
              );
            }

            if (reason === "POA_CHECK_FIRST_NAME_MISMATCH") {
              return (
                <li key={reason}>
                  <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                    <FormattedMessage id="onfido.status.poa.lysa.check.first_name_mismatch" />
                  </Snackbar>
                </li>
              );
            }

            if (reason === "POA_CHECK_LAST_NAME_MISMATCH") {
              return (
                <li key={reason}>
                  <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                    <FormattedMessage id="onfido.status.poa.lysa.check.last_name_mismatch" />
                  </Snackbar>
                </li>
              );
            }

            if (reason === "POA_CHECK_EXPIRED_DOCUMENT") {
              return (
                <li key={reason}>
                  <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                    <FormattedMessage id="onfido.status.poa.lysa.check.expired_document" />
                  </Snackbar>
                </li>
              );
            }

            if (reason === "POA_CHECK_ADDRESS_MISMATCH") {
              return (
                <li key={reason}>
                  <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                    <FormattedMessage id="onfido.status.poa.check.address_mismatch" />
                  </Snackbar>
                </li>
              );
            }

            return null;
          })}
        </ul>
      )}
    </span>
  );
};
