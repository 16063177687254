import { useCallback, useEffect, useState } from "react";

export interface JwtResponse {
  token: string;
}

export type Error = "INVALID_LINK" | "UNKNOWN_ERROR";

let token: string;

export function useOnfidoToken(getToken: () => Promise<JwtResponse>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (token) {
      return;
    }

    setLoading(true);

    getToken()
      .then((data) => {
        token = data.token;
      })
      .catch((e) => {
        console.error("Error loading onfido token", e);

        if (e.status === 422) {
          setError("INVALID_LINK");
        } else {
          setError("UNKNOWN_ERROR");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getToken]);

  const resetToken = useCallback(() => {
    token = "";
  }, []);

  const refetchToken = useCallback(() => {
    setLoading(true);
    token = "";

    getToken()
      .then((data) => {
        token = data.token;
      })
      .catch((e) => {
        console.error("Error loading onfido token", e);

        if (e.status === 422) {
          setError("INVALID_LINK");
        } else {
          setError("UNKNOWN_ERROR");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getToken]);

  return { token, loading, error, resetToken, refetchToken };
}
