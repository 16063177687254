import React, { useRef, useContext, useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import {
  Form,
  RequiredValidator,
  LysaFormRef,
  Checkbox,
  Card,
  DocModal,
} from "@lysaab/ui-2";
import { OnfidoContext } from "../../context/OnfidoContext";
import onfidoLogo from "./onfido-logo.svg";
import "./OnfidoIntroPage.scss";
import { IntroEmailCard } from "./IntroEmailCard";
import { SignupId } from "../../data/signup";
import { Button } from "@lysaab/ui-2/components/buttons/Button";
import { Language } from "../../lang/LocalizationContext";
import { LysaCountry } from "@lysaab/shared";

const messages = defineMessages({
  checkboxRequired: { id: "onfido.intro.gdpr_checkbox.required" },
  checkboxText: { id: "onfido.intro.gdpr_checkbox.text" },
  ingressMsg: { id: "onfido.intro.ingress" },
  modalAnnouncement: { id: "onfido.intro.gdpr_modal.announcement" },
  modalHeader: { id: "onfido.intro.gdpr_modal.header" },
  p1Msg: { id: "onfido.intro.p1" },
  p2Msg: { id: "onfido.intro.p2" },
});

const docList: Record<string, Record<LysaCountry, string>> = {
  personalDataProcessing: {
    [LysaCountry.SWEDEN]:
      "legal/all/en/lysas-processing-of-personal-data-when-identifying-a-customers-identity.md",
    [LysaCountry.DENMARK]:
      "legal/all/en/lysas-processing-of-personal-data-when-identifying-a-customers-identity.md",
    [LysaCountry.FINLAND]:
      "legal/all/en/lysas-processing-of-personal-data-when-identifying-a-customers-identity.md",
    [LysaCountry.GERMANY]:
      "legal/de/de/lysas-processing-of-personal-data-when-identifying-a-customers-identity.md",
    [LysaCountry.SPAIN]:
      "legal/all/en/lysas-processing-of-personal-data-when-identifying-a-customers-identity.md",
  },
};

interface PropsBasic {
  next?: () => void;
  country: LysaCountry;
  signupId?: never;
  language?: never;
}

interface PropsEmailCard {
  next?: () => void;
  signupId: SignupId;
  language: Language;
  country: LysaCountry;
}

export const OnfidoIntroPage = ({
  next,
  signupId,
  language,
  country,
}: PropsBasic | PropsEmailCard) => {
  const intl = useIntl();
  const onfidoContext = useContext(OnfidoContext);
  const formRef = useRef<LysaFormRef>();
  const submitRef = useRef<HTMLButtonElement>(null);
  const [showDoc, setShowDoc] = useState(false);

  const nextPage = () => {
    if (formRef.current && formRef.current.isValid && next) {
      next();
    }
  };

  return (
    <div className="onfido-intro-page">
      <DocModal
        header={intl.formatMessage(messages.modalHeader)}
        active={!!showDoc}
        onRequestClose={() => setShowDoc(false)}
        docList={[docList.personalDataProcessing[country]]}
        modalAnnouncement={intl.formatMessage(messages.modalAnnouncement)}
      />

      <h2>
        <FormattedMessage id="onfido.intro.title" />
      </h2>
      <Card>
        <Form onSubmit={nextPage} lysaFormRef={formRef}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "7px 0 4px 0",
            }}
          >
            <img src={onfidoLogo} className="onfido-logo" alt="Onfido logo" />
          </div>
          <p style={{ fontWeight: "bold" }}>
            {intl.formatMessage(messages.ingressMsg)}
          </p>
          <p>{intl.formatMessage(messages.p1Msg)}</p>
          <p>
            {intl.formatMessage<React.ReactNode>(messages.p2Msg, {
              link: (chunks) => (
                <button
                  type="button"
                  className="as-link"
                  key="element-key"
                  onClick={() => setShowDoc((showDoc) => !showDoc)}
                >
                  {chunks}
                </button>
              ),
            })}
          </p>

          <Checkbox
            alternative={{
              text: intl.formatMessage(messages.checkboxText),
              value: true,
            }}
            checked={onfidoContext.state.gdprCheck}
            onChange={() => {
              onfidoContext.setState({
                gdprCheck: !onfidoContext.state.gdprCheck,
              });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.checkboxRequired)
              ),
            ]}
          />
          <Button
            type="submit"
            className="intro-hidden-submit"
            ref={submitRef}
            label={"Hidden submit button"}
          />
        </Form>
      </Card>

      {signupId && language && (
        <IntroEmailCard
          signupId={signupId}
          language={language}
          country={country}
        />
      )}

      <Button
        type="button"
        block
        onClick={() => submitRef.current?.click()}
        label={<FormattedMessage id="onfido.intro.next" />}
      />
    </div>
  );
};
