import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StatusResponseId, IdNonClearedBreakdowns } from "../../../data/onfido";

const breakdowns: Record<
  IdNonClearedBreakdowns["name"],
  React.ReactElement | null
> = {
  age_validation: (
    <FormattedMessage id="onfido.status.id.non_cleared.age_validation" />
  ),
  compromised_document: (
    <FormattedMessage id="onfido.status.id.non_cleared.compromised_document" />
  ),
  data_comparison: (
    <FormattedMessage id="onfido.status.id.non_cleared.data_comparison" />
  ),
  data_consistency: (
    <FormattedMessage id="onfido.status.id.non_cleared.data_consistency" />
  ),
  data_validation: (
    <FormattedMessage id="onfido.status.id.non_cleared.data_validation" />
  ),
  image_integrity: (
    <FormattedMessage id="onfido.status.id.non_cleared.image_integrity" />
  ),
  police_record: (
    <FormattedMessage id="onfido.status.id.non_cleared.police_record" />
  ),
  visual_authenticity: (
    <FormattedMessage id="onfido.status.id.non_cleared.visual_authenticity" />
  ),
  face_comparison: (
    <FormattedMessage id="onfido.status.id.non_cleared.face_comparison" />
  ),
};

// const subBreakdowns: Record<
//   IdNonClearedSubBreakdowns["name"],
//   React.ReactElement | null
// > = {
//   colour_picture: (
//     <FormattedMessage id="onfido.status.id.non_cleared.colour_picture" />
//   ),
//   conclusive_document_quality: (
//     <FormattedMessage id="onfido.status.id.non_cleared.conclusive_document_quality" />
//   ),
//   date_of_birth: (
//     <FormattedMessage id="onfido.status.id.non_cleared.date_of_birth" />
//   ),
//   date_of_expiry: (
//     <FormattedMessage id="onfido.status.id.non_cleared.date_of_expiry" />
//   ),
//   digital_tampering: (
//     <FormattedMessage id="onfido.status.id.non_cleared.digital_tampering" />
//   ),
//   document_expiration: (
//     <FormattedMessage id="onfido.status.id.non_cleared.document_expiration" />
//   ),
//   document_numbers: (
//     <FormattedMessage id="onfido.status.id.non_cleared.document_numbers" />
//   ),
//   document_type: (
//     <FormattedMessage id="onfido.status.id.non_cleared.document_type" />
//   ),
//   expiry_date: (
//     <FormattedMessage id="onfido.status.id.non_cleared.expiry_date" />
//   ),
//   face_detection: (
//     <FormattedMessage id="onfido.status.id.non_cleared.face_detection" />
//   ),
//   face_match: <FormattedMessage id="onfido.status.id.non_cleared.face_match" />,
//   first_name: <FormattedMessage id="onfido.status.id.non_cleared.first_name" />,
//   fonts: <FormattedMessage id="onfido.status.id.non_cleared.fonts" />,
//   gender: <FormattedMessage id="onfido.status.id.non_cleared.gender" />,
//   image_quality: (
//     <FormattedMessage id="onfido.status.id.non_cleared.image_quality" />
//   ),
//   issuing_country: (
//     <FormattedMessage id="onfido.status.id.non_cleared.issuing_country" />
//   ),
//   last_name: <FormattedMessage id="onfido.status.id.non_cleared.last_name" />,
//   minimum_accepted_age: (
//     <FormattedMessage id="onfido.status.id.non_cleared.minimum_accepted_age" />
//   ),
//   mrz: <FormattedMessage id="onfido.status.id.non_cleared.mrz" />,
//   multiple_data_sources_present: (
//     <FormattedMessage id="onfido.status.id.non_cleared.multiple_data_sources_present" />
//   ),
//   nationality: (
//     <FormattedMessage id="onfido.status.id.non_cleared.nationality" />
//   ),
//   original_document_present: (
//     <FormattedMessage id="onfido.status.id.non_cleared.original_document_present" />
//   ),
//   other: <FormattedMessage id="onfido.status.id.non_cleared.other" />,
//   picture_face_integrity: (
//     <FormattedMessage id="onfido.status.id.non_cleared.picture_face_integrity" />
//   ),
//   security_features: (
//     <FormattedMessage id="onfido.status.id.non_cleared.security_features" />
//   ),
//   supported_document: (
//     <FormattedMessage id="onfido.status.id.non_cleared.supported_document" />
//   ),
//   template: <FormattedMessage id="onfido.status.id.non_cleared.template" />,
// };

// const reasons: Record<IdNonClearedReasons["name"], React.ReactElement | null> =
//   {
//     abnormal_document_features: (
//       <FormattedMessage id="onfido.status.id.non_cleared.abnormal_document_features" />
//     ),
//     blurred_photo: (
//       <FormattedMessage id="onfido.status.id.non_cleared.blurred_photo" />
//     ),
//     corner_removed: (
//       <FormattedMessage id="onfido.status.id.non_cleared.corner_removed" />
//     ),
//     covered_photo: (
//       <FormattedMessage id="onfido.status.id.non_cleared.covered_photo" />
//     ),
//     cut_off_document: (
//       <FormattedMessage id="onfido.status.id.non_cleared.cut_off_document" />
//     ),
//     damaged_document: (
//       <FormattedMessage id="onfido.status.id.non_cleared.damaged_document" />
//     ),
//     dark_photo: (
//       <FormattedMessage id="onfido.status.id.non_cleared.dark_photo" />
//     ),
//     digital_document: (
//       <FormattedMessage id="onfido.status.id.non_cleared.digital_document" />
//     ),
//     document_on_printed_paper: (
//       <FormattedMessage id="onfido.status.id.non_cleared.document_on_printed_paper" />
//     ),
//     glare_on_photo: (
//       <FormattedMessage id="onfido.status.id.non_cleared.glare_on_photo" />
//     ),
//     incorrect_side: (
//       <FormattedMessage id="onfido.status.id.non_cleared.incorrect_side" />
//     ),
//     missing_back: (
//       <FormattedMessage id="onfido.status.id.non_cleared.missing_back" />
//     ),
//     no_document_in_image: (
//       <FormattedMessage id="onfido.status.id.non_cleared.no_document_in_image" />
//     ),
//     obscured_data_points: (
//       <FormattedMessage id="onfido.status.id.non_cleared.obscured_data_points" />
//     ),
//     obscured_security_features: (
//       <FormattedMessage id="onfido.status.id.non_cleared.obscured_security_features" />
//     ),
//     other_photo_issue: (
//       <FormattedMessage id="onfido.status.id.non_cleared.other_photo_issue" />
//     ),
//     photo_of_screen: (
//       <FormattedMessage id="onfido.status.id.non_cleared.photo_of_screen" />
//     ),
//     punctured_document: (
//       <FormattedMessage id="onfido.status.id.non_cleared.punctured_document" />
//     ),
//     scan: <FormattedMessage id="onfido.status.id.non_cleared.scan" />,
//     two_documents_uploaded: (
//       <FormattedMessage id="onfido.status.id.non_cleared.two_documents_uploaded" />
//     ),
//     watermarks_digital_text_overlay: (
//       <FormattedMessage id="onfido.status.id.non_cleared.watermarks_digital_text_overlay" />
//     ),
//   };
interface Props {
  idStatus: StatusResponseId;
}

export const NonClearedBreakdownList: React.VFC<Props> = ({ idStatus }) => {
  const intl = useIntl();

  const getName = (type: typeof breakdowns, name: IdNonClearedBreakdowns["name"]) => {
    return type.hasOwnProperty(name)
      ? type[name]
      : intl.formatMessage({ id: "onfido.status.id.non_cleared.consider" }) +
          " " +
          name.replaceAll("_", " ");
  };

  if (idStatus.nonClearedBreakdowns.length) {
    return (
      <ul className="snackbar-wrapper">
        {idStatus.nonClearedBreakdowns.map((breakdown) => {
          return (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon key={breakdown.name}>
              <li key={breakdown.name}>
                {getName(breakdowns, breakdown.name)}
                {/* <ul className="list-breakdowns">
                  {breakdown.nonClearedSubBreakdowns.map((subBreakdown) => {
                    return (
                      <>
                        <li>• {getName(subBreakdowns, subBreakdown.name)}</li>

                        {subBreakdown.name !== "face_match" && (
                          <ul>
                            {subBreakdown.nonClearedReason.map((reason) => {
                              console.log("reason", reason);
                              if (reason === "face_match") {
                                return;
                              }
                              return (
                                <li key={reason}>
                                  • {getName(reasons, reason)}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </>
                    );
                  })}
                </ul> */}
              </li>
            </Snackbar>
          );
        })}
      </ul>
    );
  }

  return null;
};
