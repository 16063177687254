import React, { useRef, useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Card, Form, LysaFormRef, CheckmarkButton } from "@lysaab/ui-2";
import onfidoLogo from "./onfido-logo.svg";
import "./OnfidoPoaIntroPage.scss";
import { SignupId } from "../../data/signup";
import { Button } from "@lysaab/ui-2/components/buttons/Button";
import { Language } from "../../lang/LocalizationContext";
import { sendResumeEmailPoa } from "../../data/onfido";

const messages = defineMessages({
  ingressMsg: { id: "onfido.poa.intro.ingress" },
  p1Msg: { id: "onfido.poa.intro.p1" },
});

interface Props {
  next?: () => void;
  signupId: SignupId;
  language: Language;
}

export const OnfidoPoaIntroPage: React.FC<Props> = ({
  next,
  signupId,
  language,
}) => {
  const formRef = useRef<LysaFormRef>();
  const [success, setSuccess] = useState(false);
  const intl = useIntl();

  return (
    <div className="onfido-poa-intro-page">
      <h2>
        <FormattedMessage id="onfido.poa.intro.title" />
      </h2>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "7px 0 4px 0",
          }}
        >
          <img src={onfidoLogo} className="onfido-logo" alt="Onfido logo" />
        </div>
        <p style={{ fontWeight: "bold" }}>
          {intl.formatMessage(messages.ingressMsg)}
        </p>
        <p>{intl.formatMessage(messages.p1Msg)}</p>
        <p className="email-text">
          <FormattedMessage id="onfido.poa.intro.email.text" />
        </p>
        <Form
          lysaFormRef={formRef}
          onSubmit={(event) => {
            event.preventDefault();

            if (formRef.current?.isValid && language) {
              sendResumeEmailPoa(signupId, language).then(() => {
                setSuccess(true);
                setTimeout(() => setSuccess(false), 2000);
              });
            }
          }}
        >
          <CheckmarkButton
            type="submit"
            className="button-secondary block"
            isSuccessful={success}
          >
            <FormattedMessage id="onfido.poa.intro.button.email" />
          </CheckmarkButton>
        </Form>
      </Card>

      <Button
        type="button"
        block
        onClick={next}
        label={<FormattedMessage id="onfido.poa.intro.next" />}
      />
    </div>
  );
};
