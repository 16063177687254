import { API, encode } from "@lysaab/ui-2";

export enum OnfidoRejectReason {
  /** Something wrong when validating the document */
  ONFIDO_DOCUMENT = "ONFIDO_DOCUMENT",
  /** Drivers license instead of passport, passport instead of id card, etc */
  ONFIDO_DOCUMENT_TYPE = "ONFIDO_DOCUMENT_TYPE",
  /** Something wrong when validating the selfie */
  ONFIDO_PHOTO = "ONFIDO_PHOTO",
  /** Unknown error */
  ONFIDO_FAILED = "ONFIDO_FAILED",
}

// Not used atm
// export interface OnfidoReject {
//   firstName: string;
//   lastName: string;
//   dateOfBirth: string;
//   reason: OnfidoRejectReason;
// }

export type SignupId = string & { readonly isSignupId: true };

// Not used atm
// export function updateRejection(signupId: SignupId) {
//   const url = "/signup/person/onfido/rejection?signupId=" + signupId;

//   return API.post<Omit<OnfidoReject, "reason">>(url);
// }

export enum OnboardingStatus {
  /** Probably expired */
  NOT_FOUND = "NOT_FOUND",

  NOT_SIGNED = "NOT_SIGNED",

  /** Waiting for Onfido to validate the documents submitted by the customer */
  ONFIDO_PENDING = "ONFIDO_PENDING",
  /** Onfido's automatic validation failed. Manual review. Up to 24h */
  ONFIDO_PENDING_MANUAL_REVIEW = "ONFIDO_PENDING_MANUAL_REVIEW",
  /** User did not complete all onfido steps yet */
  ONFIDO_PENDING_USER = "ONFIDO_PENDING_USER",
  /** Unknown Onfido failure, might be a user error */
  ONFIDO_FAILED = "ONFIDO_FAILED",

  /**
   * Onfido failures that the user can fix by retrying
   */

  /** Probably a driver's license, or onfido couldn't detect the document */
  ONFIDO_FAILED_DOCUMENT_TYPE = "ONFIDO_FAILED_DOCUMENT_TYPE",
  /** Onfido has found error in the customer's documentation */
  ONFIDO_FAILED_DOCUMENT = "ONFIDO_FAILED_DOCUMENT",
  /** Onfido has found error in the customer's live photo */
  ONFIDO_FAILED_PHOTO = "ONFIDO_FAILED_PHOTO",

  /** Lysa has to do a manual review. Something looks fishy */
  PENDING_MANUAL_REVIEW = "PENDING_MANUAL_REVIEW",
  /** Signing or review is finished, started creation */
  PENDING_CREATION = "PENDING_CREATION",
  /** Customer is created and can now log in */
  COMPLETE = "COMPLETE",

  /** Too young */
  REJECTED_MINOR = "REJECTED_MINOR",
}

export interface SignupInfoResponse {
  city: string;
  dateOfBirth: Date;
  firstName: string;
  lastName: string;
  postalCode: string;
  residentCountry: string;
  street: string;
}

export interface OnboardingStatusResponse {
  trackId: string;
  status: OnboardingStatus;
}

export function getStatus(signupId: SignupId) {
  return API.get<OnboardingStatusResponse>(
    encode`/signup/person/status?signupId=${signupId}`,
    true
  );
}

export function getSignupInfo(signupId: SignupId) {
  return API.get<SignupInfoResponse>(
    encode`/signup/person?signupId=${signupId}`,
    true
  );
}
