import React from "react";
import { StatusResponseId } from "../../../data/onfido";
import { NonClearedBreakdownList } from "./NonClearedBreakdownList";

interface Props {
  idStatus: StatusResponseId;
}

export const OnfidoConsider: React.VFC<Props> = ({ idStatus }) => {
  return <NonClearedBreakdownList idStatus={idStatus} />;
};
