import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";
import { StatusResponseId } from "../../../data/onfido";
import { NonClearedBreakdownList } from "./NonClearedBreakdownList";

interface Props {
  idStatus: StatusResponseId;
}

export const OnfidoDocumentFailedType: React.VFC<Props> = ({ idStatus }) => {
  return (
    <div className="snackbar-wrapper">
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <FormattedMessage id="onfido.status.id.onfido_document_failed_type" />
      </Snackbar>
      <NonClearedBreakdownList idStatus={idStatus} />
    </div>
  );
};
