import { useState, useEffect } from "react";
import {
  getOnfidoStatus,
  StatusResponse,
  StatusResponseId,
  StatusResponsePoa,
} from "../data/onfido";
import { SignupId } from "../data/signup";

export const useOnfidoStatus = (signupId: SignupId) => {
  const [idStatus, setIdStatus] = useState<StatusResponseId>();
  const [poaStatus, setPoaStatus] = useState<StatusResponsePoa>();
  const [response, setResponse] = useState<StatusResponse[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (error) {
      return;
    }

    getOnfidoStatus(signupId)
      .then((response) => {
        const idStatus: StatusResponseId | undefined = response.find(
          (response): response is StatusResponseId =>
            response.checkType === "ID"
        );

        const poaStatuses: StatusResponsePoa[] = response.filter(
          (response): response is StatusResponsePoa =>
            response.checkType === "POA_UTILITY_BILL" ||
            response.checkType === "POA_ID_CARD"
        );

        let poaStatus = poaStatuses[0];

        if (poaStatuses.length === 2) {
          poaStatus =
            poaStatuses.find(
              (status) =>
                status.status === "ONFIDO_PENDING" ||
                status.status === "ONFIDO_PENDING_MANUAL_REVIEW"
            ) || poaStatus;
        }

        setResponse(response);
        setIdStatus(idStatus);
        setPoaStatus(poaStatus);
      })
      .catch((error) => {
        setError(error);
      });
  }, [error, signupId]);

  return {
    idStatus,
    poaStatus,
    loading: !response,
    hasStatus: response && response.length > 0,
    error,
  };
};
