import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Card, Button } from "@lysaab/ui-2";
import { SignupId } from "../../data/signup";
import "./OnfidoStatusPage.scss";
import { useParams } from "react-router-dom";
import { SignupInfo } from "./SignupInfo";
import { useOnfidoStatus } from "../../hooks/useOnfidoStatus";
import { StatusCardContent } from "./StatusCardContent";

interface Props {
  idProcess: () => void;
  poaProcess: () => void;
  restart: () => void;
}

// Possible helpful messages to have for the future
// "onfido.status.document_failed": "We could not verify that your passport is valid. Make sure that the photo is not blurry and that you have good lighting.",
// "onfido.status.document_type": "You have to use your selected form of identification. If you select passport you need to use a passport. If you tried to use a driving license, that is not a valid identity card.",
// "// onfido.status.general_info": "Your identity or address could not be verified. Please make sure that you are in a place with adequate lighting, and that you take photos with a steady hand to limit blur.",
// "onfido.status.poa.not_started": "Not started",
// "onfido.status.photo_failed": "Onfido could not verify that the photo you took of yourself matches the photo in your passport. Make sure the photo is of high quality and that you have good lighting.",
// "onfido.status.wait": "This is taking longer than expected, please wait"

export const OnfidoStatusPage = ({ idProcess, poaProcess, restart }: Props) => {
  const params = useParams<{ signupId: SignupId }>();
  const [invalid, setInvalid] = useState(false);

  const { idStatus, poaStatus, error } = useOnfidoStatus(params.signupId);

  const bothClear =
    (idStatus?.status === "ONFIDO_CLEAR" ||
      idStatus?.status === "LYSA_MANUAL_REVIEW_CLEAR") &&
    (poaStatus?.status === "ONFIDO_CLEAR" ||
      poaStatus?.status === "LYSA_MANUAL_REVIEW_CLEAR");

  const showNextButton = !bothClear && (idStatus || poaStatus);

  return (
    <div className="onfido-status-page">
      <h2>
        <FormattedMessage id="onfido.status.title" />
      </h2>
      <SignupInfo />

      <Card>
        <StatusCardContent signupId={params.signupId} />
      </Card>

      {invalid && (
        <div className="input-error">
          <FormattedMessage id="onfido.status.invalid.pending" />
        </div>
      )}

      {error && (
        <Button
          onClick={restart}
          block
          label={<FormattedMessage id="onfido.status.retry.button" />}
        />
      )}
      {bothClear && (
        <p>
          <FormattedMessage id="onfido.status.both_clear" />
        </p>
      )}
      {showNextButton && (
        <Button
          block
          onClick={() => {
            if (
              idStatus?.status === "LYSA_MANUAL_REVIEW" ||
              idStatus?.status === "ONFIDO_PENDING" ||
              idStatus?.status === "ONFIDO_PENDING_MANUAL_REVIEW" ||
              poaStatus?.status === "LYSA_MANUAL_REVIEW" ||
              poaStatus?.status === "ONFIDO_PENDING" ||
              poaStatus?.status === "ONFIDO_PENDING_MANUAL_REVIEW"
            ) {
              setInvalid(true);
              return;
            }

            if (!invalid) {
              if (
                idStatus?.status === "ONFIDO_CLEAR" ||
                idStatus?.status === "LYSA_MANUAL_REVIEW_CLEAR"
              ) {
                poaProcess();
                return;
              }

              idProcess();
            }
          }}
          label={<FormattedMessage id="onfido.status.next" />}
        />
      )}
    </div>
  );
};
