import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";
import { StatusResponseId } from "../../../data/onfido";
// import { ManualRejectReasonId } from "../../../data/onfido";

// ID_CHECK_MULTIPLE_CUSTOMERS and ID_CHECK_MULTIPLE_PASSPORTS should never be active since the signup is removed if that happens.
// If we are in this step that means first name or last name is wrong

// const reasons: Record<ManualRejectReasonId, React.ReactElement | null> = {
//   ID_CHECK_FIRST_NAME_MISMATCH: (
//     <FormattedMessage id="onfido.status.id.lysa_first_name_mismatch" />
//   ),
//   ID_CHECK_LAST_NAME_MISMATCH: (
//     <FormattedMessage id="onfido.status.id.lysa_last_name_mismatch" />
//   ),
//   ID_CHECK_MULTIPLE_CUSTOMERS: (
//     <FormattedMessage id="onfido.status.id.lysa_multiple_customers" />
//   ),
//   ID_CHECK_MULTIPLE_PASSPORTS: (
//     <FormattedMessage id="onfido.status.id.lysa_multiple_passport" />
//   ),
// };

interface Props {
  idStatus: StatusResponseId;
}

export const LysaManualReviewRejected: React.VFC<Props> = ({ idStatus }) => {
  return (
    <div className="snackbar-wrapper">
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <FormattedMessage id="onfido.status.id.lysa_manual_review_rejected" />
      </Snackbar>
      {/* {idStatus.manualRejectReasons.length && (
        <ul>
          {idStatus.manualRejectReasons.map((reason) => {
            return <li key={reason}>{reasons[reason]}</li>;
          })}
        </ul>
      )} */}
    </div>
  );
};
