import { LysaCountry } from "@lysaab/shared";
import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { confirmId, getToken, GetTokenData } from "../data/onfidoDemo";
import { SignupId } from "../data/signup";
import { LocalizationContext } from "../lang/LocalizationContext";
import { OnfidoProcessPage } from "../pages/onfido/OnfidoProcessPage";

interface DemoOnfidoProcessPageProps {
  next: () => void;
  getTokenData: GetTokenData;
}

export const DemoOnfidoIdPage = ({
  next,
  getTokenData,
}: DemoOnfidoProcessPageProps) => {
  const params = useParams<{ signupId: SignupId }>();
  const localizationContext = useContext(LocalizationContext);

  const country = localizationContext.state.country || LysaCountry.DENMARK;
  const language = localizationContext.state.language;

  const getTokenCb = useCallback(
    () => getToken({ ...getTokenData, signupId: params.signupId }),
    [getTokenData, params.signupId]
  );

  const onConfirmCb = useCallback(() => {
    return confirmId(params.signupId);
  }, [params.signupId]);

  const onUserErrorCb = useCallback((e: unknown) => {
    console.log("User did a no-no", e);
  }, []);

  const onFatalErrorCb = useCallback(() => {
    console.log("Fatal error is fatal ☠");
  }, []);

  return (
    <OnfidoProcessPage
      confirm={onConfirmCb}
      next={next}
      onFatalError={onFatalErrorCb}
      getToken={getTokenCb}
      country={country}
      locale={language}
      phrases={{}}
      steps={["document", "face"]}
      onUserError={onUserErrorCb}
      restart={() => {
        console.log("restart()");
      }}
      signupId={params.signupId}
    />
  );
};
