import React, { useReducer } from "react";

// Types for the state
export interface OnfidoState {
  gdprCheck: boolean;
  firstName?: string;
  lastName?: string;
  birthday?: Date | null;
}

export const EMPTY_ONFIDO_STATE: OnfidoState = {
  gdprCheck: false,
};

// All types for the context. This includes setter methods
export interface OnfidoContextProps {
  state: OnfidoState;
  setState: (newState: Partial<OnfidoState>) => void;
}

// Create the context. (This will just create a template)
export const OnfidoContext = React.createContext<OnfidoContextProps>(
  {} as OnfidoContextProps
);

/**
 * A Higher Order Component. This is used to wrap classes so that the context
 * is injected.
 * There is quite a bit TS magic here. This is so that the internal props off
 * the component will be stripped of the context props so that the external
 * props will only show the relevant props
 */
export const withOnfido = <P extends object>(
  Component: React.ComponentType<React.PropsWithChildren<P>>
): React.FC<React.PropsWithChildren<Omit<P, keyof OnfidoContextProps>>> => (props) => (
  <OnfidoContext.Consumer>
    {(contextProps) => <Component {...(props as P)} {...contextProps} />}
  </OnfidoContext.Consumer>
);

function stateReducer(state: OnfidoState, newState: Partial<OnfidoState>) {
  return { ...state, ...newState };
}

export const OnfidoContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, EMPTY_ONFIDO_STATE);

  return (
    <OnfidoContext.Provider value={{ state, setState }}>
      {children}
    </OnfidoContext.Provider>
  );
};
