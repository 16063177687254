import React, { FunctionComponent, useReducer } from "react";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import { MessageDescriptor } from "react-intl";
import { LysaCountry } from "@lysaab/shared";

export enum Language {
  SWEDISH = "sv",
  ENGLISH = "en",
  GERMAN = "de",
}

// Types for the state
export interface LocalizationState {
  language: Language;
  country?: LysaCountry;
}

// All types for the context. This includes setter methods
export interface LocalizationContextProps {
  state: LocalizationState;
  setState: (newState: Partial<LocalizationState>) => void;
  getLocale: () => string;
}

// Create the context. (This will just create a template)
export const LocalizationContext =
  React.createContext<LocalizationContextProps>({} as LocalizationContextProps);

/**
 * A Higher Order Component. This is used to wrap classes so that the context is injected
 * There is quite a bit TS magic here. This is so that the internal props off the component
 * will be stripped of the context props so that the external props will only show the relevant
 * props
 */
export const withLocalization =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof LocalizationContextProps>>> =>
  (props) =>
    (
      <LocalizationContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </LocalizationContext.Consumer>
    );

function stateReducer(
  state: LocalizationState,
  newState: Partial<LocalizationState>
) {
  return { ...state, ...newState };
}

export const LocalizationContextProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, setState] = useReducer(stateReducer, {
    language: Language.ENGLISH,
  });

  const getLocale = () => {
    let locale: string = state.language;
    if (state.country) {
      locale = `${state.language}-${state.country}`;
    }

    return locale;
  };

  return (
    <LocalizationContext.Provider value={{ state, setState, getLocale }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export type FormatMessageFn = (
  descriptor: MessageDescriptor,
  values?: Record<
    string,
    | PrimitiveType
    | React.ReactNode
    | FormatXMLElementFn<React.ReactNode, string>
  >
) => React.ReactNode | string;

export function formatOnfidoMessage(
  formatMessage: FormatMessageFn,
  messageDescriptor: MessageDescriptor
) {
  return formatMessage(messageDescriptor, {
    fallback: (str: any) => `<fallback>${str}</fallback>`,
    strong: (str: any) => `<strong>${str}</strong>`,
    country: "%{country}",
    number: "%{number}",
    side: "%{side}",
  });
}
