import React, { useRef, useState } from "react";
import { Card, Form, LysaFormRef, CheckmarkButton } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import "./IntroEmailCard.scss";
import { Language } from "../../lang/LocalizationContext";
import { SignupId } from "../../data/signup";
import { sendResumeEmailId } from "../../data/onfido";
import { LysaCountry } from "@lysaab/shared";

interface Props {
  signupId: SignupId;
  language: Language;
  country: LysaCountry;
}

export const IntroEmailCard: React.VFC<Props> = ({
  signupId,
  language,
  country,
}) => {
  const formRef = useRef<LysaFormRef>();
  const [success, setSuccess] = useState(false);

  return (
    <Card>
      <p className="email-text">
        <FormattedMessage
          id="onfido.intro.email.text"
          values={{ country: country.toString() }}
        />
      </p>
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();

          if (formRef.current?.isValid && language) {
            sendResumeEmailId(signupId, language).then(() => {
              setSuccess(true);
              setTimeout(() => setSuccess(false), 2000);
            });
          }
        }}
      >
        <CheckmarkButton
          type="submit"
          variant="secondary"
          className="block"
          isSuccessful={success}
        >
          <FormattedMessage id="onfido.intro.button.email" />
        </CheckmarkButton>
      </Form>
    </Card>
  );
};
