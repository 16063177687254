import { API, encode } from "@lysaab/ui-2";
import { Language } from "../lang/LocalizationContext";
import { SignupId } from "./signup";

export enum ConfirmStatus {
  OK = "OK",
  ONFIDO_USER_ERROR = "ONFIDO_USER_ERROR",
}

export interface ConfirmResponse {
  checkStatus: ConfirmStatus;
}

interface SubBreakdown {
  result: "clear" | "caution" | "suspected" | "rejected";
  name: string;
  clear: boolean;
  nonClearedReason: string[];
}

interface PoaImageQualitySubBreakdown extends SubBreakdown {
  name: "image_quality";
}

interface PoaSupportedDocumentSubBreakdown extends SubBreakdown {
  name: "supported_document";
}

interface PoaDocumentDateSubBreakdown extends SubBreakdown {
  name: "valid_document_date";
}

interface PoaFirstNameSubBreakdown extends SubBreakdown {
  name: "first_name";
}

interface PoaLastNameSubBreakdown extends SubBreakdown {
  name: "last_name";
}

interface PoaAddressSubBreakdown extends SubBreakdown {
  name: "address";
}

interface MinimumAcceptedAgeSubBreakdown extends SubBreakdown {
  name: "minimum_accepted_age";
}

interface DateOfBirthSubBreakdown extends SubBreakdown {
  name: "date_of_birth";
}

interface DateOfExpirySubBreakdown extends SubBreakdown {
  name: "date_of_expiry";
}

interface DocumentNumbersSubBreakdown extends SubBreakdown {
  name: "document_numbers";
}

interface DocumentTypeSubBreakdown extends SubBreakdown {
  name: "document_type";
}

interface FirstNameSubBreakdown extends SubBreakdown {
  name: "first_name";
}

interface GenderSubBreakdown extends SubBreakdown {
  name: "gender";
}

interface IssuingCountrySubBreakdown extends SubBreakdown {
  name: "issuing_country";
}

interface LastNameSubBreakdown extends SubBreakdown {
  name: "last_name";
}

interface MultipleDataSourcesPresentSubBreakdown extends SubBreakdown {
  name: "multiple_data_sources_present";
}

interface NationalitySubBreakdown extends SubBreakdown {
  name: "nationality";
}

interface DocumentExpirationSubBreakdown extends SubBreakdown {
  name: "document_expiration";
}

interface ExpiryDateSubBreakdown extends SubBreakdown {
  name: "expiry_date";
}

interface MrzSubBreakdown extends SubBreakdown {
  name: "mrz";
}

interface ColourPictureSubBreakdown extends SubBreakdown {
  name: "colour_picture";
}

interface ConclusiveDocumentQualitySubBreakdown extends SubBreakdown {
  name: "conclusive_document_quality";
}

interface ImageQualitySubBreakdown extends SubBreakdown {
  name: "image_quality";
}

interface SupportedDocumentSubBreakdown extends SubBreakdown {
  name: "supported_document";
}

interface DigitalTamperingSubBreakdown extends SubBreakdown {
  name: "digital_tampering";
}

interface FaceDetectionSubBreakdown extends SubBreakdown {
  name: "face_detection";
}

interface FontsSubBreakdown extends SubBreakdown {
  name: "fonts";
}

interface OriginalDocumentPresentSubBreakdown extends SubBreakdown {
  name: "original_document_present";
}

interface OtherSubBreakdown extends SubBreakdown {
  name: "other";
}

interface PictureFaceIntegritySubBreakdown extends SubBreakdown {
  name: "picture_face_integrity";
}
interface FaceMatchSubBreakdown extends SubBreakdown {
  name: "face_match";
}

interface SecurityFeaturesSubBreakdown extends SubBreakdown {
  name: "security_features";
}

interface TemplateSubBreakdown extends SubBreakdown {
  name: "template";
}
interface PhotoOfScreenReason {
  name: "photo_of_screen";
}
interface DocumentOnPrintedPaperReason {
  name: "document_on_printed_paper";
}
interface ScanReason {
  name: "scan";
}
interface ObscuredDataPointsReason {
  name: "obscured_data_points";
}
interface ObscuredSecurityFeaturesReason {
  name: "obscured_security_features";
}
interface AbnormalDocumentFeaturesReason {
  name: "abnormal_document_features";
}
interface WatermarksDigitalTextOverlayReason {
  name: "watermarks_digital_text_overlay";
}
interface CornerRemovedReason {
  name: "corner_removed";
}
interface PuncturedDocumentReason {
  name: "punctured_document";
}
interface MissingBackReason {
  name: "missing_back";
}
interface DigitalDocumentReason {
  name: "digital_document";
}
interface DarkPhotoReason {
  name: "dark_photo";
}
interface GlareOnPhotoReason {
  name: "glare_on_photo";
}
interface BlurredPhotoReason {
  name: "blurred_photo";
}
interface CoveredPhotoReason {
  name: "covered_photo";
}
interface OtherPhotoIssueReason {
  name: "other_photo_issue";
}
interface DamagedDocumentReason {
  name: "damaged_document";
}
interface IncorrectSideReason {
  name: "incorrect_side";
}
interface CutOffDocumentReason {
  name: "cut_off_document";
}
interface NoDocumentInImageReason {
  name: "no_document_in_image";
}
interface TwoDocumentsUploadedReason {
  name: "two_documents_uploaded";
}

interface BaseBreakdown {
  name: string;
  result: "clear" | "consider";
  clear: boolean;
  nonClearedSubBreakdowns: SubBreakdown[];
}

interface AgeValidationBreakdown extends BaseBreakdown {
  name: "age_validation";
  nonClearedSubBreakdowns: MinimumAcceptedAgeSubBreakdown[];
}

interface CompromisedDocumentBreakdown extends BaseBreakdown {
  name: "compromised_document";
  nonClearedSubBreakdowns: [];
}

interface DataComparisonBreakdown extends BaseBreakdown {
  name: "data_comparison";
  nonClearedSubBreakdowns: (
    | DateOfBirthSubBreakdown
    | DateOfExpirySubBreakdown
    | DocumentNumbersSubBreakdown
    | DocumentTypeSubBreakdown
    | FirstNameSubBreakdown
    | GenderSubBreakdown
    | IssuingCountrySubBreakdown
    | LastNameSubBreakdown
  )[];
}

interface DataConsistencyBreakdown extends BaseBreakdown {
  name: "data_consistency";
  nonClearedSubBreakdowns: (
    | DateOfBirthSubBreakdown
    | DateOfExpirySubBreakdown
    | DocumentNumbersSubBreakdown
    | FirstNameSubBreakdown
    | GenderSubBreakdown
    | IssuingCountrySubBreakdown
    | LastNameSubBreakdown
    | MultipleDataSourcesPresentSubBreakdown
    | NationalitySubBreakdown
  )[];
}

interface DataValidationBreakdown extends BaseBreakdown {
  name: "data_validation";
  nonClearedSubBreakdowns: (
    | DateOfBirthSubBreakdown
    | DocumentExpirationSubBreakdown
    | DocumentNumbersSubBreakdown
    | ExpiryDateSubBreakdown
    | GenderSubBreakdown
    | MrzSubBreakdown
  )[];
}

interface ImageIntegrityBreakdown extends BaseBreakdown {
  name: "image_integrity";
  nonClearedSubBreakdowns: (
    | ColourPictureSubBreakdown
    | ConclusiveDocumentQualitySubBreakdown
    | ImageQualitySubBreakdown
    | SupportedDocumentSubBreakdown
  )[];
}

interface FaceComparisonBreakdown extends BaseBreakdown {
  name: "face_comparison";
  nonClearedSubBreakdowns: FaceMatchSubBreakdown[];
}

interface PoliceRecordBreakdown extends BaseBreakdown {
  name: "police_record";
  nonClearedSubBreakdowns: [];
}

interface VisualAuthenticityBreakdown extends BaseBreakdown {
  name: "visual_authenticity";
  nonClearedSubBreakdowns: (
    | DigitalTamperingSubBreakdown
    | FaceDetectionSubBreakdown
    | FontsSubBreakdown
    | OriginalDocumentPresentSubBreakdown
    | OtherSubBreakdown
    | PictureFaceIntegritySubBreakdown
    | SecurityFeaturesSubBreakdown
    | TemplateSubBreakdown
  )[];
}

interface PoaImageIntegrityBreakdown extends BaseBreakdown {
  name: "image_integrity";
  nonClearedSubBreakdowns: PoaImageQualitySubBreakdown[];
}

interface PoaDocumentClassificationBreakdown extends BaseBreakdown {
  name: "document_classification";
  nonClearedSubBreakdowns: (
    | PoaSupportedDocumentSubBreakdown
    | PoaDocumentDateSubBreakdown
  )[];
}

interface PoaDataComparisonBreakdown extends BaseBreakdown {
  name: "data_comparison";
  nonClearedSubBreakdowns: (
    | PoaFirstNameSubBreakdown
    | PoaLastNameSubBreakdown
    | PoaAddressSubBreakdown
  )[];
}

export type ManualRejectReasonId =
  /** Multiple passports/id-cards used for signing up same customer */
  | "ID_CHECK_MULTIPLE_PASSPORTS"
  /** Same passport/id-card has been used to onboard another customer */
  | "ID_CHECK_MULTIPLE_CUSTOMERS"
  | "ID_CHECK_FIRST_NAME_MISMATCH"
  | "ID_CHECK_LAST_NAME_MISMATCH";

type ManualRejectReasonPoa =
  /** The document is not valid for address match */
  | "POA_CHECK_UNSUPPORTED_DOCUMENT"
  /** Image quality obstructs address match */
  | "POA_CHECK_INSUFFICIENT_IMAGE_QUALITY"
  | "POA_CHECK_FIRST_NAME_MISMATCH"
  | "POA_CHECK_LAST_NAME_MISMATCH"
  | "POA_CHECK_EXPIRED_DOCUMENT"
  | "POA_CHECK_ADDRESS_MISMATCH";

type OnfidoStatus =
  | "ONFIDO_PENDING"
  | "ONFIDO_PENDING_MANUAL_REVIEW"
  | "LYSA_CANCELED"
  | "ONFIDO_FAILED_STARTED"
  | "ONFIDO_FAILED_USER_ERROR"
  | "ONFIDO_CONSIDER"
  | "ONFIDO_CLEAR";

type LysaStatus =
  | "LYSA_MANUAL_REVIEW"
  | "LYSA_MANUAL_REVIEW_CLEAR"
  | "LYSA_MANUAL_REVIEW_REJECTED";

export type IdNonClearedBreakdowns =
  | AgeValidationBreakdown
  | CompromisedDocumentBreakdown
  | DataComparisonBreakdown
  | DataConsistencyBreakdown
  | DataValidationBreakdown
  | ImageIntegrityBreakdown
  | PoliceRecordBreakdown
  | VisualAuthenticityBreakdown
  | ImageIntegrityBreakdown
  | FaceComparisonBreakdown;

// Contains second level breakdowns
export type IdNonClearedSubBreakdowns =
  | MinimumAcceptedAgeSubBreakdown
  | DateOfBirthSubBreakdown
  | DateOfExpirySubBreakdown
  | DocumentNumbersSubBreakdown
  | DocumentNumbersSubBreakdown
  | DocumentTypeSubBreakdown
  | FirstNameSubBreakdown
  | GenderSubBreakdown
  | IssuingCountrySubBreakdown
  | LastNameSubBreakdown
  | MultipleDataSourcesPresentSubBreakdown
  | NationalitySubBreakdown
  | DocumentExpirationSubBreakdown
  | ExpiryDateSubBreakdown
  | MrzSubBreakdown
  | ColourPictureSubBreakdown
  | ConclusiveDocumentQualitySubBreakdown
  | ImageQualitySubBreakdown
  | SupportedDocumentSubBreakdown
  | DigitalTamperingSubBreakdown
  | FaceDetectionSubBreakdown
  | FontsSubBreakdown
  | OriginalDocumentPresentSubBreakdown
  | OtherSubBreakdown
  | PictureFaceIntegritySubBreakdown
  | FaceMatchSubBreakdown
  | SecurityFeaturesSubBreakdown
  | TemplateSubBreakdown;

// Contains third level breakdowns
export type IdNonClearedReasons =
  | PhotoOfScreenReason
  | DocumentOnPrintedPaperReason
  | ScanReason
  | ObscuredDataPointsReason
  | ObscuredSecurityFeaturesReason
  | AbnormalDocumentFeaturesReason
  | WatermarksDigitalTextOverlayReason
  | CornerRemovedReason
  | PuncturedDocumentReason
  | MissingBackReason
  | DigitalDocumentReason
  | DarkPhotoReason
  | GlareOnPhotoReason
  | BlurredPhotoReason
  | CoveredPhotoReason
  | OtherPhotoIssueReason
  | DamagedDocumentReason
  | IncorrectSideReason
  | CutOffDocumentReason
  | NoDocumentInImageReason
  | TwoDocumentsUploadedReason;

export interface StatusResponseId {
  checkType: "ID";
  status: OnfidoStatus | LysaStatus | "ONFIDO_DOCUMENT_FAILED_TYPE";
  nonClearedBreakdowns: IdNonClearedBreakdowns[];
  manualRejectReasons: ManualRejectReasonId[];
}

export type PoaNonClearedBreakdowns =
  | PoaImageIntegrityBreakdown
  | PoaDocumentClassificationBreakdown
  | PoaDataComparisonBreakdown;

export type PoaSubBreakdowns =
  | PoaAddressSubBreakdown
  | PoaLastNameSubBreakdown
  | PoaFirstNameSubBreakdown
  | PoaSupportedDocumentSubBreakdown
  | PoaImageQualitySubBreakdown
  | PoaDocumentDateSubBreakdown;

export interface StatusResponsePoa {
  checkType: "POA_UTILITY_BILL" | "POA_ID_CARD";
  status: OnfidoStatus | LysaStatus;
  nonClearedBreakdowns: PoaNonClearedBreakdowns[];
  manualRejectReasons: ManualRejectReasonPoa[];
}

export type StatusResponse = StatusResponseId | StatusResponsePoa;

export function logSignup(signupId: SignupId, event: any) {
  const url = encode`/onfido/onboarding/log?signupId=${signupId}`;

  return API.post(url, {
    event: JSON.stringify({ ...event, signupId }, null, 2),
  });
}

export function logReset(resetId: ResetId, event: any) {
  const url = encode`/onfido/authenticationreset/log?authenticationResetId=${resetId}`;

  return API.post(url, {
    event: JSON.stringify({ ...event, resetId }, null, 2),
  });
}

export type ResetId = string & { readonly isResetId: true };

export function sendResumeEmail(signupId: SignupId, language: Language) {
  const url = "/onfido/onboarding/resume";
  return API.post(url, { signupId, language });
}

export function sendResumeEmailId(signupId: SignupId, language: Language) {
  const url = "/onfido/onboarding/resume-id";
  return API.post(url, { signupId, language });
}

export function sendResumeEmailPoa(signupId: SignupId, language: Language) {
  const url = "/onfido/onboarding/resume-poa";
  return API.post(url, { signupId, language });
}

export function identityEnhancedCheck(signupId: SignupId) {
  const url = encode`/onfido/onboarding/poa/identityEnhanced/confirm?signupId=${signupId}`;
  return API.post(url);
}

export function getOnfidoStatus(signupId: SignupId) {
  const url = encode`/onfido/onboarding/status?signupId=${signupId}`;
  return API.get<StatusResponse[]>(url, true);
}
